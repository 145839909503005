import React, { useEffect, useState } from "react";
import { BookiePromo, getPromosByState } from "./promo-finder.service";

import { signal } from "@preact/signals-react";
import { getImagePathByMarketKey } from "../../../services/bet-finder-service";
import { bookieSignUpLinks } from "../common/bookie-image";
import { formatDollarAmount } from "../../../services/common-util-service";


const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Copied to clipboard:", text);
      // You can add further feedback like a success message if needed
    },
    (err) => {
      console.error("Failed to copy:", err);
    }
  );
};

// Create a signal to store the user's state
export const userStateSignal = signal<string>("");

// Fetch the user's state based on IP address
export const fetchUserState = async (): Promise<void> => {
  try {
    const url = "https://ipapi.co/json/";
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();

      // Check if the user is in the United States
      if (data.country_code === "US") {
        userStateSignal.value = data.region;
      } else {
        userStateSignal.value = "";
      }
    } else {
      console.error("Failed to fetch geolocation data.");
      userStateSignal.value = "";
    }
  } catch (error) {
    console.error("Error fetching user state:", error);
    userStateSignal.value = "";
  }
};

fetchUserState();
export const PromoCard: React.FC<{ promo: BookiePromo }> = ({ promo }) => {
  // State to manage the toast visibility
  const [showToast, setShowToast] = useState(false);

  // Function to copy text to clipboard
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setShowToast(true); // Show toast notification
        setTimeout(() => setShowToast(false), 2000); // Hide after 2 seconds
      },
      (err) => {
        console.error("Failed to copy:", err);
      }
    );
  };


  return (
  <div className="bg-[#011d47e8] md-brder p-4 rounded-lg shadow-md w-full max-w-[700px]">
    <div className="flex items-center">
      {/* Left: Bookmaker logo */}
      <div className="w-[66px]  h-[66px] bg-gray-600 flex items-center justify-center rounded">
        <img
          src={promo.bookieKey ? getImagePathByMarketKey(promo.bookieKey) : "/placeholder.png"}
          alt={promo.name}
          className="w-full h-full  rounded-md"
        />
      </div>

      {/* Right: Bookmaker name and promo details */}
      <div className="flex-1 ml-4">
        <div className="flex items-center">
          {/* Bookmaker Name */}
          <h3 className="text-lg font-bold text-white">{promo.name}</h3>

          {/* Promo Amount */}
          <span className="ev-green ev-green-border text-white rounded-full mx-2 px-3 py-1 text-sm font-semibold">
            {formatDollarAmount(promo.promoAmount)}
          </span>
        </div>

        {/* Promo Description */}
        <p className="text-gray-300 text-left mt-2">{promo.promoDescription}</p>

        {/* {promo.promoCode ? (
        <div className="mb-4">
          <p className="text-gray-400 mb-2">
            Copy and paste this code at {promo.name}:
          </p>
          <div className="flex items-center">
            <span className="bg-[#0A1E3B] text-white border border-white py-1 px-3 rounded-md mr-2">
              {promo.promoCode}
            </span>
            <button className="text-blue-400 hover:underline">Copy</button>
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <p className="text-gray-500 italic">
            No promo code required. Click to claim.
          </p>
        </div>
      )} */}
      </div>
    </div>

    <div className="mt-4">
      {promo.promoCode ? (
        <div className="flex flex-col">
          {/* Promo code instructions */}
          <p className="text-gray-400 font-bold text-[14px] mb-2">
            Copy and paste this code at {promo.name}
          </p>

          {/* Promo Code and Claim Button */}
          <div className="flex items-center space-x-4">
            {/* Promo Code Box with Copy Button inside */}
            <div className="flex items-center bg-[#0A1E3B] text-white border border-white py-2 px-2 rounded-md">
              <span>{promo.promoCode}</span>
              <button
                className="text-blue-400 hover:underline ml-4"
                onClick={() => copyToClipboard(promo.promoCode??"")}
              >
                Copy
              </button>
            </div>


            <a  target="_blank" rel="noopener noreferrer" href={bookieSignUpLinks[promo.bookieKey??""] || '#'} title={promo.promoDescription || 'Sign Up'}  style={{ display: 'inline-block' }}>
            {/* Claim Button */}
            <button  className="bg-powder-bloo text-[#0A1E3B] text-[14px] font-bold rounded-md py-2 px-6 flex items-center justify-center min-h-[44px] w-100 max-h-[44px]">
              Claim ${promo.promoAmount}
            </button>
            </a>
          </div>
        </div>
      ) : (
        <div>
          {/* If no promo code, just show the claim button */}
          <a href={bookieSignUpLinks[promo.bookieKey??""] || '#'} title={promo.promoDescription || 'Sign Up'} >
          <button className="bg-blue-500 rounded-md px-4 py-2 w-full bg-powder-bloo text-[#0A1E3B] text-[14px] font-bold">
            Claim ${promo.promoAmount}
          </button>
          </a>
        </div>
      )}
    </div>
    {showToast && (
        <div className="absolute top-0 right-0 mt-2 mr-2 bg-green-500 text-white px-4 py-2 rounded">
          Copied to clipboard!
        </div>
      )}
  </div>
 );
};

export const PromoFinder: React.FC = () => {
  const [numPromosToShow, setNumPromosToShow] = useState(3);
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   fetchUserState();
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setNumPromosToShow(3); // Large screens
      } else if (window.innerWidth >= 640) {
        setNumPromosToShow(2); // Medium screens
      } else {
        setNumPromosToShow(1); // Small screens
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const state = userStateSignal.value;
  const filteredPromos = getPromosByState(state);
  const totalPromoAmount = filteredPromos.reduce(
    (sum, promo) => sum + promo.promoAmount,
    0
  );

  const promosToShow = filteredPromos.slice(0, numPromosToShow);

  if(!state || 0===totalPromoAmount) return null;

  return (
    <section>
      <div className="py-10 sm-pad md-pad lg:px-10 md:px-6 max-w-[1500px] mx-auto text-center text-white">
        <div className="min-w-[300px] max-w-[85%] mx-auto max-w-[1000px]">
          <h1 className="lg:text-4xl md:text-3xl text-3xl pt-5 tracking-wide ">
            Get up to{" "}
            <span className="text-blue-400">
            <strong>{formatDollarAmount(totalPromoAmount)}</strong>
            </span>{" "}
            in promos, just by signing up with{" "}
            <span className="text-blue-400 capitalize">
              <strong>{state}</strong>
            </span>{" "}
            sportsbooks.
          </h1>
          <p className="lg:text-2xl md:text-xl text-md mt-5 pb-2">
            Sportsbooks give out ALOT of bonus bet credits to get you to try
            them. We’ll show you exactly how to take advantage of these promos.
          </p>
        </div>
        {/* Promo Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {promosToShow.map((promo) => (
            <PromoCard key={promo.bookieKey} promo={promo} />
          ))}
        </div>

        <div className="mt-8">
          <a href="/sportsbook-promo-finder" className="text-blue-400 underline">
            View all
          </a>
        </div>
      </div>
    </section>
  );
};



// const PromoModal: React.FC<{ promos: BookiePromo[]; onClose: () => void }> = ({
//   promos,
//   onClose,
// }) => {
//   return (
//     <Modal
//       isOpen={true}
//       onRequestClose={onClose}
//       contentLabel="All Promos"
//       className="relative bg-[#011d47] p-6 rounded-lg shadow-lg w-full max-w-2xl overflow-auto outline-none"
//       overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
//     >
//       <div className="flex justify-between items-center mb-6">
//         <h2 className="text-2xl font-bold text-white">All Promos</h2>
//         <button
//           onClick={onClose}
//           className="text-white text-2xl p-2 rounded-full bg-gray-800 hover:bg-gray-600"
//           aria-label="Close"
//         >
//           &times;
//         </button>
//       </div>

//       {/* Promo List */}
//       <div className="space-y-4 max-h-[80vh] overflow-y-auto">
//         {promos.map((promo) => (
//           <PromoCard key={promo.bookieKey} promo={promo} />
//         ))}
//       </div>
//     </Modal>
//   );
// };
