import React, { useState } from 'react';
import { getRequest, postRequest, putRequest, patchRequest, deleteRequest } from '../../services/apiService';

const Util = () => {
  const [uri, setUri] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET');
  const [requestBody, setRequestBody] = useState('');

  const methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

  const handleSubmit = async () => {
    let response;
    let parsedRequestBody;

  if (requestBody && requestBody.trim() !== '') {
    parsedRequestBody = JSON.parse(requestBody);
}
    switch(httpMethod) {
      case 'GET':
        response = await getRequest(uri);
        break;
      case 'POST':
        response = await postRequest(uri, parsedRequestBody);
        break;
      case 'PUT':
        response = await putRequest(uri, parsedRequestBody);
        break;
      case 'PATCH':
        response = await patchRequest(uri, parsedRequestBody);
        break;
      case 'DELETE':
        response = await deleteRequest(uri);
        break;
      default:
        break;
    }
  }

  return (
    <div className='d-flex align-items-center"'>
      <input 
        type='text' 
        placeholder='URI' 
        value={uri}
        onChange={e => setUri(e.target.value)}
      />

      <select 
        value={httpMethod}
        onChange={e => setHttpMethod(e.target.value)}
      >
        {methods.map(method => 
          <option key={method} value={method}>{method}</option>
        )}
      </select>

      <textarea 
        placeholder='Request Body' 
        value={requestBody}
        onChange={e => setRequestBody(e.target.value)}
        disabled={httpMethod === 'GET' || httpMethod === 'DELETE'}
      />

      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default Util;
